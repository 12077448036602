










































import { Component, Vue, Watch } from "vue-property-decorator";
import { apiGet } from "../store";
import HelpBlock from "@/components/HelpBlock.vue";

@Component({
  components: {
    HelpBlock,
  },
})
export default class ExternalHelp extends Vue {
  public $store: any;
  public block: any = {};
  public loading = false;
  public cache: any = {};

  public async mounted() {
    await this.load();
  }

  @Watch("$route")
  async load() {
    this.loading = true;
    this.block = {};
    let slug: string = this.$route.params.pathMatch || "/help";
    // remove potential /help/help
    if (slug.startsWith("/help/help")) {
      slug = slug.substring(5);
    }
    if (this.cache[slug]) {
      this.block = this.cache[slug];
      this.loading = false;
      return;
    } else {
      const res: any = await apiGet(`/help${slug}`);
      this.block = res;
      this.loading = false;
      this.cache[slug] = this.block;
    }
  }

  get isSubpage() {
    const slug = this.$route.params.pathMatch || "/help";
    console.log(slug);
    // check if latest part of the slug is a uuid 32 chars
    if (slug.match(/-[a-f0-9]{32}$/)) {
      return true;
    }
  }
}
